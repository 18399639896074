<template>
 <div></div> 
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    this.$router.push('/openJNJUser');
  }
}
</script>